<div class="kiosk-topnav">
    <div class="topnav">
        <a class="kiosk-title">
            <h1>{{ 'SETTINGS.TITLE' | translate }} {{ 'SETTINGS.SUBTITLE' | translate }}</h1>
        </a>
        <div class="floating-button-container">
            <button class="xrx-btn btn-primary w-125 btn-padding" type="button" (click)="onSubmit()"
                [disabled]="settingsForm.invalid">
                <i class="xgl-checkmark_circle_two_tone xglsize-24 btn-icon-align"></i>
                <div class="button-text">
                    {{ 'SETTINGS.OK' | translate }}
                </div>
            </button>
        </div>
    </div>
</div>


<div class="content">
    <div class="bg_color ml-1">
        <form [formGroup]="settingsForm" class="col-12 form-group">

            <!-- Device Admin User Name Field -->
            <div class="marginTop10">
                <label class="subtitle-2">
                    <p>{{ 'SETTINGS.DEVICE_ADMIN_USERNAME' | translate }}</p>
                </label>
                <div class="marginBottom10">
                    <input type="text" #device_admin_name formControlName="deviceAdminName" (keyup.Enter)="onEnterKeyClicked($event)" class="form-control form input_style " />
                    <div *ngIf="settingsForm.controls['deviceAdminName'].invalid && (settingsForm.controls['deviceAdminName'].dirty || settingsForm.controls['deviceAdminName'].touched)"
                        class="alert">
                        <div class="input-error" *ngIf="settingsForm.controls['deviceAdminName'].errors.required">
                            <p>{{'SETTINGS.MANDATORY'| translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="isAuthenticationError" class="text-danger error-label">
                      {{ 'SETTINGS.USERNAME_PASSWORD_INVALID' | translate }}
                    </div>
                </div>

            </div>

            <!-- Device Admin User Name Field -->
            <div>
                <label class="subtitle-2">
                    <p>{{ 'SETTINGS.DEVICE_ADMIN_PASSWORD' | translate }}</p>
                </label>
                <div class="marginBottom30">
                    <input type="text" #device_admin_password formControlName="deviceAdminPassword" (keyup.Enter)="onEnterKeyClicked($event)" class="form-control form input_style" />
                    <div *ngIf="settingsForm.controls['deviceAdminPassword'].invalid && (settingsForm.controls['deviceAdminPassword'].dirty || settingsForm.controls['deviceAdminPassword'].touched)"
                        class="alert">
                        <div class="input-error" *ngIf="settingsForm.controls['deviceAdminPassword'].errors.required">
                            <p>{{'SETTINGS.MANDATORY'| translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="isAuthenticationError" class="text-danger error-label">
                      {{ 'SETTINGS.USERNAME_PASSWORD_INVALID' | translate }}
                    </div>
                </div>

            </div>

            <!-- SNMP Community Read String Field -->
            <div>
                <label class="subtitle-2">
                    <p>{{ 'SETTINGS.SNMP_COMMUNITY_READ_STRING' | translate }}</p>
                </label>
                <div class="marginBottom10">
                    <input type="text" #snmp_community_read_string formControlName="snmpCommunityReadString" (keyup.Enter)="onEnterKeyClicked($event)"
                        class="form-control form input_style " />
                    <div *ngIf="settingsForm.controls['snmpCommunityReadString'].invalid && (settingsForm.controls['snmpCommunityReadString'].dirty || settingsForm.controls['snmpCommunityReadString'].touched)"
                        class="alert">
                        <div class="input-error"
                            *ngIf="settingsForm.controls['snmpCommunityReadString'].errors.required">
                            <p>{{'SETTINGS.MANDATORY'| translate}}</p>
                        </div>
                    </div>
                    <!-- <div *ngIf="isSNMPError" class="text-danger error-label">
                      {{ 'SETTINGS.SNMP_STRING_INVALID' | translate }}
                    </div> -->
                </div>

            </div>

            <!-- SNMP Community Write String Field -->
            <div>
                <label class="subtitle-2">
                    <p>{{ 'SETTINGS.SNMP_COMMUNITY_WRITE_STRING' | translate }}</p>
                </label>
                <div class="marginBottom30">
                    <input type="text" #snmp_community_write_string formControlName="snmpCommunityWriteString" (keyup.Enter)="onEnterKeyClicked($event)"
                        class="form-control form input_style" />
                    <div *ngIf="settingsForm.controls['snmpCommunityWriteString'].invalid && (settingsForm.controls['snmpCommunityWriteString'].dirty || settingsForm.controls['snmpCommunityWriteString'].touched)"
                        class="alert">
                        <div class="input-error"
                            *ngIf="settingsForm.controls['snmpCommunityWriteString'].errors.required">
                            <p>{{'SETTINGS.MANDATORY'| translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="isSNMPError" class="text-danger error-label">
                      {{ 'SETTINGS.SNMP_STRING_INVALID' | translate }}
                    </div>
                </div>

            </div>

            <div class="marginBottom30 ">
                <div class="row">
                    <input type="checkbox" id="switch" formControlName="enableKiosk" class="checkbox" />

                    <label for="switch" class="toggle subtitle-2">
                        <p class="enable_kiosk">{{ 'SETTINGS.ENABLE_KIOSK' | translate }}</p>
                    </label>
                </div>
            </div>

            <!-- Reset Default Button -->
            <div class="btn-font">
                <button class="xrx-btn w-100 btn-container" type="button" #reset_button (click)="resetToDefault()">
                    <div>
                        <i class="xgl-reset xglsize-24 reset_icon"></i>
                    </div>    
                    <div class="reset_button_text">
                        {{ 'SETTINGS.RESET_TO_DEFAULTS' | translate }}
                    </div>
                </button>
            </div>
        </form>
    </div>
</div>

<app-xrx-modal #errorModal [showTitle]="true" [showDescription]="true"></app-xrx-modal>

<app-thankyou-modal #savingSettings [showTitle]="false" [showDescription]="true" [loadingIcon]="true"></app-thankyou-modal>

<app-xrx-modal #restartMFD [showClose]="false" [showTitle]="true" title="{{ 'SETTINGS.REBOOT_REQUIRED' | translate }}">
    <div class="modal-footer text-center">
        <button class="xrx-btn btn-primary restart-button" (click)="restartDevice()">{{'SETTINGS.REBOOT_DEVICE' | translate }}</button>
    </div>
</app-xrx-modal>